<template>
  <div class="relative">
    <div
      ref="sl"
      class="keen-slider sl-slider max-sm:!overflow-visible"
      :class="{'!overflow-visible': visible}"
    >
      <div
        v-for="(item, index) in this.tagsObj"
        :key="index"
        class="keen-slider__slide mb-12 group flex flex-col !overflow-visible rounded-[10px] bg-white px-25 pb-25 pt-45 text-center max-sm:p-25"
      >
        <h3 class="block font-bionM text-xl uppercase">
          {{ item?.title }}
        </h3>
        <span class="mb-5 block">{{ item?.children[0]?.handle }}</span>
        <img
          :src="item?.url"
          :alt="item?.title"
          class="mx-auto mb-15 h-[244px] w-[244px] object-cover"
        />
        <p class="leading-[22px]">
          {{ item?.children[1]?.handle }}
        </p>
      </div>
    </div>
    <!-- Slider Controllers -->
    <div
      class="mt-50 flex items-center justify-between gap-5 max-sm:mt-35"
      :class="{'sm:hidden': this.tagsObj?.length <= 2}"
    >
      <button
        @click="
          () => {
            slider.prev();
          }
        "
      >
        <svg
          width="12"
          height="22"
          viewBox="0 0 16 27"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_60_93)">
            <path
              d="M13.5193 25.4783L2.48083 13.5L13.5193 1.52168"
              stroke="#3F3F3F"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_60_93">
              <rect
                width="16"
                height="27"
                fill="white"
                transform="translate(16 27) rotate(-180)"
              />
            </clipPath>
          </defs>
        </svg>
      </button>

      <!-- dots -->
      <div class="flex w-full justify-center">
        <div
          v-if="slider"
          class="dots-gift flex flex-wrap justify-center gap-10"
        >
          <button
            type="button"
            v-for="(_slide, idx) in dotHelper.slice(0, -1)"
            @click="
              () => {
                slider.moveToIdx(idx);
              }
            "
            :class="{dot: true, active: current === idx}"
            :key="idx"
          ></button>
        </div>
      </div>

      <button
        @click="
          () => {
            slider.next();
          }
        "
      >
        <svg
          width="12"
          height="22"
          viewBox="0 0 16 27"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_60_91)">
            <path
              d="M2.48071 1.52173L13.5192 13.5L2.48071 25.4783"
              stroke="#3F3F3F"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_60_91">
              <rect width="16" height="27" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import {computed, ref} from "vue";
import {useKeenSlider} from "keen-slider/vue.es";
import "keen-slider/keen-slider.min.css";

export default {
  name: "IngredientsSlider",
  props: {
    views: {
      type: Number,
      default: 4,
    },
    mob: {
      type: Number,
      default: 1.5,
    },
    controls: {
      type: Boolean,
      default: false,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    tags: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    tagsObj() {
      return this.tags?.filter(tag => tag);
    },
  },
  setup(props) {
    const current = ref(0);
    const [sl, slider] = useKeenSlider({
      loop: false,
      breakpoints: {
        "(max-width: 1025px)": {
          slides: {
            perView: 2,
            spacing: 20,
          },
        },
        "(max-width: 767px)": {
          slides: {
            perView: 1.1,
            spacing: 20,
          },
        },
        "(max-width: 640px)": {
          slides: {
            perView: props.mob,
            spacing: 20,
          },
        },
      },
      slides: {
        perView: props.views,
        spacing: 18,
      },
      slideChanged: s => {
        current.value = s.track.details.rel;
      },
    });
    const dotHelper = computed(() =>
      slider.value
        ? [...Array(slider?.value?.track?.details?.slides?.length).keys()]
        : [],
    );

    return {sl, current, dotHelper, slider};
  },
  // mounted() {
  //   console.log(this.tagsObj?.length);
  // },
};
</script>
<style>
.dot {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: transparent;
  border: 1px solid #3f3f3f;
}

.dot:after {
  display: none !important;
}

.dot.active {
  background-color: #90c540;
  border-color: transparent;
}
.slider-buttons-m .btn-block {
  @apply !border-[2px] bg-white;
}
.slider-buttons-m .full-stars {
  @apply text-ss3_purple;
}
.slider-buttons-m .dot.active {
  @apply border-transparent bg-pastelPinkL;
}
</style>
