<template>
  <div
    class="flex h-[28px] w-[100px] flex-[0_0_100px] items-center font-bionM text-ss3_gray"
  >
    <button
      aria-label="Decrease Quantity by 5"
      @click.prevent="decreaseQuantity"
      class="px-5 text-xl"
    >
      -
    </button>

    <label for="quantity-value" class="flex">
      <input
        id="quantity-value"
        type="text"
        v-model="localQuantity"
        @input="updateLocalQuantity"
        @keypress="allowOnlyNumbers"
        @blur="setDefaultValue"
        @focus="clearInput"
        @keydown.enter.prevent="moveToNextSection($event)"
        class="w-[3ch] bg-transparent text-center outline-none"
      />lbs
    </label>

    <button
      aria-label="Increase Quantity by 5"
      @click.prevent="increaseQuantity"
      class="px-5 text-xl"
    >
      +
    </button>
  </div>
</template>

<script>
import {mapState, mapActions} from "vuex";

export default {
  name: "Quantity",
  computed: {
    ...mapState("dog-weight", ["quantity", "openAccordion"]),
    localQuantity: {
      get() {
        return this.quantity;
      },
      set(value) {
        this.updateQuantity(Math.min(Math.max(value, 5), 200));
      },
    },
  },
  methods: {
    ...mapActions("dog-weight", [
      "increaseQuantity",
      "decreaseQuantity",
      "updateQuantity",
      "setOpenAccordion",
    ]),
    allowOnlyNumbers(event) {
      if (!/^\d$/.test(event.key)) {
        event.preventDefault();
      }

      if (this.localQuantity && this.localQuantity.toString().length >= 3) {
        event.preventDefault();
      }
    },
    increaseQuantity() {
      if (this.localQuantity < 200) {
        this.updateQuantity(this.localQuantity + 5);
      }
    },

    decreaseQuantity() {
      if (this.localQuantity > 5) {
        this.updateQuantity(this.localQuantity - 5);
      }
    },
    updateLocalQuantity(event) {
      let value = event.target.value.replace(/\D/g, "");
      if (value === "") {
        this.localQuantity = null;
      } else {
        this.updateQuantity(Math.min(Math.max(parseInt(value, 10), 5), 200));
      }
    },

    clearInput(event) {
      event.target.value = "";
    },
    setDefaultValue() {
      if (!this.localQuantity) {
        this.updateQuantity(20);
      }
    },
    moveToNextSection(event) {
      if (!this.openAccordion) {
        this.setOpenAccordion();
      }
      this.$nextTick(() => {
        const plansSection = document.querySelector(".accordion-plans");
        if (plansSection) {
          plansSection.scrollIntoView({behavior: "smooth"});
        }
      });
      event.target.blur();
    },
  },
};
</script>
