<template>
  <div class="relative flex max-sm:flex-col-reverse sm:gap-10">
    <div
      class="flex gap-10 max-sm:mt-[-20px] max-sm:justify-center sm:flex-[0_0_85px] sm:flex-col"
    >
      <img
        v-for="(image, index) in imagesArr"
        :key="index"
        :src="image.src"
        alt="image"
        class="block cursor-pointer object-contain transition-all duration-300 hover:scale-105 max-sm:w-40 max-sm:flex-[0_0_40px]"
        @click="onImage(index)"
      />
    </div>

    <Fancybox
      :options="{
        Dots: true,

        Thumbs: false,
        Navigation: {
          prevTpl: `<svg xmlns='http://www.w3.org/2000/svg' width='12' height='20' viewBox='0 0 12 20' fill='none'>
        <g clip-path='url(#clip0_1511_423)'>
          <path d='M10.0479 18.8728L1.84359 9.99999L10.0479 1.12718' stroke='#3F3F3F' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/>
        </g>
        <defs>
          <clipPath id='clip0_1511_423'>
            <rect width='11.8919' height='20' fill='white' transform='translate(11.8916 20) rotate(-180)'/>
          </clipPath>
        </defs>
      </svg>`,
          nextTpl: `<svg xmlns='http://www.w3.org/2000/svg' width='12' height='20' viewBox='0 0 12 20' fill='none'>
        <g clip-path='url(#clip0_1511_426)'>
          <path d='M1.84351 1.1272L10.0478 10L1.84351 18.8728' stroke='#3F3F3F' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/>
        </g>
        <defs>
          <clipPath id='clip0_1511_426'>
            <rect width='11.8919' height='20' fill='white' transform='translate(-0.000244141)'/>
          </clipPath>
        </defs>
      </svg>`,
        },
      }"
      :imgs="imagesArr"
      class="pb-50 max-sm:pb-35"
      :key="selectedIndex"
    >
      <div
        class="f-carousel__slide"
        :data-thumb-src="imagesArr?.src"
        v-for="(image, index) in imagesArr"
        :key="index"
      >
        <img
          :src="image?.src"
          :alt="image?.alt"
          data-fancybox="gallery"
          class="h-full w-full object-cover"
          v-if="visibleImage"
        />
      </div>
      <svg
        v-if="data?.bg"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 299 307"
        fill="currentColor"
        class="absolute left-0 top-[-30px] z-[-1] h-full w-full object-contain"
        :style="`color:${data?.bg}`"
      >
        <path
          d="M216.504 278.091C222.704 264.713 221.646 251.643 219.893 237.349C219.213 231.808 218.082 226.343 216.691 220.954C228.48 225.892 241.145 227.591 253.984 224.669C273.413 220.247 287.853 205.905 295.166 187.025C304.143 163.849 295.412 138.225 282.966 118.657C282.032 117.191 281.08 115.739 280.126 114.287C285.969 109.026 290.683 102.393 292.801 94.568C295.892 83.1472 296.057 69.9724 290.13 59.4409C279.888 41.249 265.509 24.4124 247.426 14.3896C235.167 7.59767 222.289 1.43364 208.121 0.818098C192.027 0.120237 174.645 8.53147 166.014 23.039C162.006 29.7766 159.528 36.3229 158.062 43.6078C155.677 42.3795 153.258 41.2321 150.779 40.3543C142.129 37.2878 131.969 37.1934 123.054 38.9235C110.518 41.3546 98.1042 50.475 91.3716 61.6436C85.8326 70.8319 83.4705 81.4098 82.9564 92.1274C82.7069 97.332 82.7962 102.581 83.1119 107.828C78.1311 101.926 71.8853 97.1837 64.5238 95.0499C53.7944 91.9426 40.5862 91.4904 30.7797 97.9711C21.9725 103.794 14.7791 109.796 9.18117 119.227C3.81048 128.279 0.533048 139.002 0.275297 149.647C-0.2474 171.263 9.7879 191.33 26.945 203.38C34.995 209.035 43.6743 213.726 51.715 219.45C59.929 225.297 67.9117 231.484 75.7467 237.866C91.5045 250.698 106.638 264.327 121.898 277.79C129.009 284.063 135.992 291.159 143.701 296.622C152.081 302.56 161.927 305.651 171.967 306.337C191.007 307.632 208.424 295.512 216.498 278.09L216.504 278.091ZM77.8763 173.198C77.6751 173.344 77.477 173.488 77.2758 173.634C77.5332 173.42 77.7936 173.206 78.0482 172.989C77.9918 173.06 77.9325 173.13 77.8763 173.198Z"
          fill="currentColor"
        />
      </svg>
    </Fancybox>
  </div>
</template>

<script>
import {mapState} from "vuex";
import Fancybox from "../Fancybox/index.vue";

export default {
  name: "pdp-gallery",
  components: {
    Fancybox,
  },
  props: {
    images: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      data: JSON.parse(this.images),
      visibleImage: false,
    };
  },
  computed: {
    ...mapState("product", ["selectedIndex"]),
    imagesArr() {
      return this.data?.singleVariant
        ? this.data?.images
        : this.data?.images[this.selectedIndex];
    },
  },
  methods: {
    onImage(index) {
      const dots = document.querySelectorAll(".f-carousel__dots  li button");
      if (dots[index]) {
        dots[index].click();
      }
    },
    fixImages() {
      setTimeout(() => {
        this.visibleImage = true;
      }, 100);
    },
  },
  watch: {
    selectedIndex() {
      this.visibleImage = false;
      this.fixImages();
    },
  },
  mounted() {
    // console.log(this.data, this.selectedIndex);
    this.fixImages();
    // console.log(this.imagesArr);
  },
};
</script>
<style>
:root {
  --f-carousel-dot-width: 14px;
  --f-carousel-dot-height: 14px;
  --f-carousel-dot-opacity: 1;
  --f-carousel-dot-color: #3f3f3f;
}
.f-carousel__nav .f-button.is-prev {
  left: 0 !important;
}
.f-carousel__nav .f-button.is-next {
  right: 0 !important;
}
.f-carousel__dots {
  bottom: -15px;
  @apply max-sm:hidden;
}
.f-carousel.has-dots {
  @apply max-sm:!mb-0;
}
.f-carousel__dot {
  border: 1px solid #3f3f3f;
  background: transparent;
}
.is-current .f-carousel__dot {
  @apply !border-[#90C340] !bg-[#90C340];
}
.f-carousel__nav .f-button svg {
  stroke: transparent;
}
.f-carousel__nav .f-button:hover path {
  stroke: #93297a;
}
.f-carousel__nav .f-button {
  top: 100% !important;
}
</style>
