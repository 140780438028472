<template>
  <div class="accordion">
    <div
      v-for="(tab, index) in tabs"
      :key="index"
      class="accordion__item border-b-darkBlue border-b-[2px] last:border-b-0"
    >
      <div
        @click="toggleTab(index)"
        class="accordion__header relative flex cursor-pointer items-center justify-between"
      >
        <span
          class="text-darkBlue relative py-15 text-[20px] font-bold uppercase max-sm:text-base"
          >{{ tab }}
        </span>
        <span v-if="isTabOpen(index)" class="text-ss3_gray">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="4"
            viewBox="0 0 24 4"
            fill="currentColor"
          >
            <path
              d="M22 2L2 2"
              stroke="currentColor"
              stroke-width="3"
              stroke-linecap="round"
            />
          </svg>
        </span>
        <span v-else class="text-ss3_gray">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M12 2L12 22"
              stroke="currentColor"
              stroke-width="3"
              stroke-linecap="round"
            />
            <path
              d="M22 12.2565L2 12.2565"
              stroke="currentColor"
              stroke-width="3"
              stroke-linecap="round"
            />
          </svg>
        </span>
      </div>
      <div
        v-if="isTabOpen(index)"
        class="accordion__content py-10 max-sm:text-base"
      >
        <slot :name="normalizeSlotName(tab)"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Accordion",
  data() {
    return {
      openTabs: [0],
    };
  },
  props: {
    tabs: {
      type: Array,
      required: true,
    },
  },
  computed: {
    isAnyTabOpen() {
      return this.openTabs.length > 0;
    },
  },
  methods: {
    toggleTab(index) {
      if (this.openTabs.includes(index)) {
        this.openTabs = [];
      } else {
        this.openTabs = [index];
      }
    },
    isTabOpen(index) {
      return this.openTabs.includes(index);
    },
    normalizeSlotName(name) {
      return name.replace(/\s+/g, "_").toLowerCase();
    },
  },
};
</script>
