const state = {
  quantity: 20,
  openAccordion: false,
};

const mutations = {
  setQuantity(state, value) {
    state.quantity = value;
  },
  increaseQuantity(state, amount) {
    state.quantity += amount;
  },
  decreaseQuantity(state, amount) {
    if (state.quantity > amount) {
      state.quantity -= amount;
    }
  },
  toggleAccordion(state) {
    state.openAccordion = !state.openAccordion;
  },
};

const actions = {
  updateQuantity({commit}, value) {
    commit("setQuantity", value);
  },
  increaseQuantity({commit}) {
    commit("increaseQuantity", 5);
  },
  decreaseQuantity({commit}) {
    commit("decreaseQuantity", 5);
  },
  setOpenAccordion({commit}) {
    commit("toggleAccordion");
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
