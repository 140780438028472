<template>
  <nav
    class="nav max-xl:overflow-y-auto"
    :aria-labelledby="menuId"
    :class="{active: open}"
  >
    <div class="flex justify-end" @click="openMenu">
      <svg
        class="nav__close mb-20 text-ss3_gray"
        fill="currentColor"
        viewBox="0 0 16 16"
      >
        <g>
          <path
            d="M14.7,1.3c-0.4-0.4-1-0.4-1.4,0L8,6.6L2.7,1.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4L6.6,8l-5.3,5.3 c-0.4,0.4-0.4,1,0,1.4C1.5,14.9,1.7,15,2,15s0.5-0.1,0.7-0.3L8,9.4l5.3,5.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L9.4,8l5.3-5.3C15.1,2.3,15.1,1.7,14.7,1.3z"
          ></path>
        </g>
      </svg>
    </div>
    <slot></slot>
    <ul class="nav__list flex items-center xl:gap-30">
      <li
        v-for="(item, index) in menu"
        :key="item.handle"
        class="nav__li group py-[39px]"
        :class="{
          active: item.active,
          hidden: step !== index && !allOpened,
        }"
      >
        <div
          class="relative flex items-center justify-between gap-5"
          :class="{
            'py-[16px] uppercase before:absolute before:left-[-43px] before:top-0 before:-z-[1] before:h-full before:w-[341px] before:bg-[#EDE2E6]':
              isOpen[index],
            hidden: !allOpenedSub,
          }"
        >
          <span
            v-if="item.children.length > 0 && isOpen[index]"
            class="hidden max-xl:block"
            @click.stop="onBack"
          >
            <ArrRight class="rotate-[182deg]" />
          </span>
          <a :href="item.url">{{ item.title }}</a>
          <span
            v-if="item.children.length > 0 && !isOpen[index]"
            class="hidden max-xl:block"
            @click.stop="toggleSubmenu(index, item.level, false)"
          >
            <ArrRight />
          </span>
          <span v-else></span>
        </div>
        <!-- mobile -->
        <ul
          class="left-0 top-[103%] z-20 w-[16em] overflow-hidden group-hover:visible group-hover:top-[100%] group-hover:opacity-100 max-xl:h-0 max-xl:shadow-none xl:invisible xl:absolute xl:!hidden xl:bg-pastelPink xl:opacity-0"
          :class="{
            'max-xl:!h-full': isOpen[index],
            '!overflow-visible': !allOpenedSub,
          }"
        >
          <li
            v-for="(subitem, index) in item.children"
            :key="index"
            :class="{
              hidden: subIndex !== index && !allOpenedSub,
            }"
          >
            <div
              class="relative flex items-center justify-between"
              :class="{
                'py-[6px] uppercase before:absolute before:left-[-43px] before:top-0 before:-z-[1] before:h-full before:w-[341px] before:bg-[#EDE2E6]':
                  subIndex === index && !allOpenedSub,
              }"
            >
              <span
                v-if="subIndex === index && !allOpenedSub"
                class="hidden max-xl:block"
                @click.stop="onBackLevelTwo"
              >
                <ArrRight class="rotate-[182deg]" />
              </span>
              <a
                class="hover:bg-darkBlue block p-15 text-[16px] font-normal normal-case tracking-normal transition-all duration-300 max-xl:px-5 max-xl:py-10"
                :href="subitem.url"
                :key="index"
              >
                {{ subitem.title }}
              </a>

              <button
                @click.stop="toggleSubSubmenu(subitem.level, index)"
                v-if="allOpenedSub && subitem.children.length > 0"
              >
                <ArrRight />
              </button>
              <span v-else></span>
            </div>

            <template v-if="level === subitem.level">
              <a
                v-for="(subitemlevel, index) in subitem.children"
                :key="index"
                class="hover:bg-darkBlue block p-15 text-[16px] font-normal normal-case tracking-normal transition-all duration-300 max-xl:px-5 max-xl:py-10"
                :href="subitemlevel.url"
              >
                {{ subitemlevel.title }}
              </a>
            </template>
          </li>
        </ul>

        <!-- desktop -->
        <div
          v-if="item.children.length > 0"
          class="left-0 top-[157px] z-20 w-full overflow-hidden shadow-md duration-100 ease-in-out group-hover:visible group-hover:opacity-100 max-xl:!hidden max-xl:hidden max-xl:h-0 max-xl:shadow-none max-xl:transition-all xl:invisible xl:fixed xl:bg-pastelPink xl:opacity-0"
          :class="{
            'max-xl:!h-full': isOpen[index],
          }"
        >
          <div class="container flex max-w-[1320px] gap-70 pb-[50px] pt-10">
            <div
              v-for="(subitem, index) in subitemsWithChildren(item.children)"
              :key="index"
              class="flex-[0_1_auto]"
            >
              <a
                class="mb-10 block text-[16px] font-bold normal-case tracking-normal transition-all duration-300 hover:text-ss3_purple"
                :href="subitem.url"
                :key="index"
              >
                {{ subitem.title }}
              </a>
              <ul>
                <li
                  v-for="(sublevelitem, index) in subitem.children"
                  :key="index"
                  class="mb-5 last:mb-0"
                >
                  <a
                    class="block text-[16px] font-normal normal-case transition-all duration-300 hover:text-ss3_purple"
                    :href="sublevelitem.url"
                    :key="index"
                  >
                    {{ sublevelitem.title }}
                  </a>
                </li>
              </ul>
            </div>

            <ul>
              <li
                v-for="(subitem, index) in subitemsWithoutChildren(
                  item.children,
                )"
                :key="index"
                class="mb-5 font-normal first:font-bold last:mb-0"
              >
                <a
                  class="hover:text-darkBlue tracking-norma block text-[16px] normal-case transition-all duration-300 hover:text-ss3_purple"
                  :href="subitem.url"
                  :key="index"
                >
                  {{ subitem.title }}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </li>
    </ul>
  </nav>

  <div class="icon-parent xl:hidden">
    <button class="icon-menu" :class="{_active: open}" @click="openMenu">
      <span></span>
      <span></span>
      <span></span>
    </button>
  </div>
</template>

<script>
import {mapState, mapGetters, mapActions} from "vuex";
import ArrRight from "../Icons/ArrRight.vue";
export default {
  name: "SiteNavigation",
  props: {
    menuId: {
      type: String,
      required: true,
    },
    menu: {
      type: Array,
      required: true,
    },
  },
  data: () => {
    return {
      open: false,
      isOpen: [],
      level: null,
      allOpened: true,

      step: 0,
      subIndex: 0,
      allOpenedSub: true,
    };
  },
  components: {
    ArrRight,
  },
  computed: {},
  methods: {
    openMenu(event) {
      if (!this.open) {
        event.stopPropagation();
        this.open = true;
        document.body.classList.add("menu-open");
        document.addEventListener("click", this.closeMenuOutside);
      } else {
        this.closeMenuOutside(event);
      }
    },
    closeMenuOutside(event) {
      const nav = document.querySelector(".nav");
      const icon = document.querySelector(".nav__close");
      if (!nav.contains(event.target) || event.target === icon) {
        this.open = false;
        document.body.classList.remove("menu-open");
        document.removeEventListener("click", this.closeMenuOutside);
      }
    },
    toggleSubmenu(index, level, opened) {
      this.level = null;
      (this.allOpened = opened), (this.step = index);
      this.isOpen[index] = !this.isOpen[index];
    },
    subitemsWithChildren(obj) {
      return obj.filter(
        subitem => subitem.children && subitem.children.length > 0,
      );
    },
    subitemsWithoutChildren(obj) {
      return obj.filter(
        subitem => !subitem.children || subitem.children.length === 0,
      );
    },
    toggleSubSubmenu(level, index) {
      // this.step = level
      this.level = level;
      this.subIndex = index;
      this.allOpenedSub = false;
    },
    onBack() {
      this.allOpened = true;
      this.levelOne = null;
      this.isOpen = [];
    },
    onBackLevelTwo() {
      this.allOpenedSub = true;
      this.subIndex = 1;
      this.level = null;
    },
  },
  mounted() {
    // console.log(this.menu);
    this.isOpen = new Array(this.menu.length).fill(false);
  },
};
</script>
<style>
.type-three .nav__li:nth-child(5) {
  margin-left: auto;
}
</style>
