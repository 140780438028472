import {endpoints, headerConfigs} from "../../utils/config";

import axios from "axios";

/**
 * state
 */
const state = {
  visible: false,
  visibleSingleProduct: false,
  cartData: false,
  loading: false,
  lastAddedItem: null,
};

/**
 * getters
 */
const getters = {
  visible: () => state.visible,
  cartCount: () => state.cartData.item_count,
};

/**
 * mutations
 */
const mutations = {
  TOGGLE(state) {
    state.visible = !state.visible;
  },

  SHOW(state) {
    state.visible = true;
  },

  HIDE(state) {
    state.visible = false;
  },
  TOGGLE_SINGLE(state) {
    state.visibleSingleProduct = !state.visibleSingleProduct;
  },

  SHOW_SINGLE(state) {
    state.visibleSingleProduct = true;
  },

  HIDE_SINGLE(state) {
    state.visibleSingleProduct = false;
  },

  cartLoading(state) {
    state.loading = !state.loading;
    // console.log("mutatuion loading!!!!");
  },

  initCart(state, payload) {
    state.cartData = payload;
  },
  updateLastAddedItem(state, item) {
    state.lastAddedItem = item;
  },
};

/**
 * actions
 */
const actions = {
  toggle({commit}) {
    commit("TOGGLE");

    const htmlElement = document.querySelector("html");
    if (htmlElement.style.overflow !== "hidden") {
      htmlElement.style.overflow = "hidden";
    } else {
      htmlElement.style.overflow = "";
    }
  },

  show({commit}) {
    commit("SHOW");
  },

  hide({commit}) {
    commit("HIDE");
  },
  toggleSingle({commit}) {
    commit("TOGGLE_SINGLE");
  },

  showSingle({commit}) {
    commit("SHOW_SINGLE");
  },

  hideSingle({commit}) {
    commit("HIDE_SINGLE");
  },

  async initCart({commit}) {
    return axios
      .get(endpoints.cart)
      .then(response => {
        commit("initCart", response.data);
        const srCartAlert = document.getElementById("sr-cart-alert");
        if (srCartAlert) {
          const itemCount = state.cartData.item_count;
          const newItem = document.createElement("span");
          newItem.textContent = `Product added to cart. You now have ${itemCount} items in your cart.`;
          srCartAlert.appendChild(newItem);
        }
        return response.data;
      })
      .catch(error => {
        return error.message;
      });
  },

  removeItem: ({commit, state}, payload) => {
    if (state.loading) return;
    commit("cartLoading");
    // console.log('Remove item 1st', state.loading);

    let products = Array.isArray(payload) ? payload : [payload];

    const mappedPayload = products.reduce((acc, i) => {
      acc[i.key] = 0;
      return acc;
    }, {});

    axios
      .post(
        endpoints.update,
        {
          updates: mappedPayload,
        },
        headerConfigs,
      )
      .then(response => {
        commit("initCart", response.data);
        commit("cartLoading");
        // console.log('Remove item 2st', state.loading);
      })
      .catch(error => {
        return error.message;
      });
  },

  addItem: ({commit, state, dispatch}, payload) => {
    // console.log('-----', state.loading);
    // console.log(payload);
    if (state.loading) return;
    commit("cartLoading");
    // console.log("Add item 1st", state.loading);

    let products = payload.items ? [payload.items[0]] : [payload];

    axios
      .post(
        endpoints.add,
        {
          items: products,
        },
        headerConfigs,
      )
      .then(res => {
        if (res?.data?.items[0]) {
          commit("updateLastAddedItem", res?.data?.items[0]);
        }
        dispatch("initCart").then(() => {
          //dispatch("show");
          dispatch("showSingle");
          commit("cartLoading");

          // console.log('Add item 2st', state.loading);
        });
        if (payload.promocode) {
          axios.get(`/discount/${payload.promocode}`);
        }
      })
      .catch(error => {
        // return error.message;
        console.log("Error adding item to cart:", error.message);
        commit("cartLoading");
        // console.log('Add item 3st', state.loading);
      });
  },

  updateItem: ({commit, state, dispatch}, payload) => {
    if (state.loading) return;
    commit("cartLoading");
    // console.log('Update item 1st', state.loading);
    const updates = {};
    const {item, type} = payload;

    const quantity =
      type === "increment" ? item.quantity + 1 : item.quantity - 1;

    if ((type === "decrement" && item.quantity > 0) || type === "increment") {
      updates[item.key] = quantity;

      axios
        .post(
          endpoints.update,
          {
            updates,
          },
          headerConfigs,
        )
        .then(() => {
          dispatch("initCart");
          commit("cartLoading");
          // console.log('Update item 2st', state.loading);
        })
        .catch(error => {
          return error.message;
        });
    }
  },
  updateNote: async ({dispatch, commit}, message) => {
    try {
      commit("cartLoading");
      // console.log('Update note 1st', state.loading);
      await axios.post(endpoints.update, {note: message}, headerConfigs);
      dispatch("initCart");
      commit("cartLoading");
      // console.log('Update note 2st', state.loading);
    } catch (error) {
      console.error(error);
    }
  },
};

/**
 * export
 */
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
