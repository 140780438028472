<script>
import {mapState, mapActions} from "vuex";

export default {
  name: "AddToCart",
  data: () => {
    return {
      loading: false,
      foo: "bar",
      selling_plan: null,
    };
  },
  computed: {
    ...mapState("product", ["quantity", "selectedVariant", "sellingPlan"]),

    // disabled() {
    //   return this.loading || !this.selectedVariant;
    // },
  },
  methods: {
    ...mapActions("cart", ["addItem"]),
    handleSubmit(idProduct) {
      // console.log("handle submit: ", idProduct);

      const id =
        typeof idProduct === "object" && idProduct instanceof Event
          ? this.selectedVariant?.id || this.selectedVariant
          : idProduct;
      console.log(id);
      if (this.sellingPlan || this.selling_plan) {
        this.addItem({
          id: id,
          quantity: this.quantity || 1,
          selling_plan: this.sellingPlan || this.selling_plan,
        });
      } else {
        this.addItem({
          id: id,
          quantity: this.quantity || 1,
        });
      }
    },
  },

  render() {
    return this.$slots.default({
      loading: this.loading,
      disabled: this.disabled,
      addToCart: this.handleSubmit,
    });
  },
};
</script>
